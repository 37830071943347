
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "d54c8a26-1f1b-4813-9e83-b5ecdaaee9df"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/root/injective/injective-explorer/node_modules/.c12/github_InjectiveLabs_injective_1IfPyDVKuA/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, inlineConfig)
