import revive_payload_client_4sVQNw7RlN from "/root/injective/injective-explorer/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/root/injective/injective-explorer/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/root/injective/injective-explorer/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/root/injective/injective-explorer/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/root/injective/injective-explorer/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/root/injective/injective-explorer/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/root/injective/injective-explorer/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/root/injective/injective-explorer/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import composition_sLxaNGmlSL from "/root/injective/injective-explorer/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/root/injective/injective-explorer/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/root/injective/injective-explorer/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import buffer_igPb1coxil from "/root/injective/injective-explorer/node_modules/.c12/github_InjectiveLabs_injective_1IfPyDVKuA/nuxt-config/buffer.ts";
import tracking_liTG5GM0Yx from "/root/injective/injective-explorer/node_modules/.c12/github_InjectiveLabs_injective_1IfPyDVKuA/plugins/tracking.ts";
import handlers_oZWLY9pUCB from "/root/injective/injective-explorer/plugins/handlers.ts";
import store_9xNuDHGAVU from "/root/injective/injective-explorer/plugins/store.ts";
import vee_validate_KcKlKmvCrJ from "/root/injective/injective-explorer/plugins/vee-validate.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  buffer_igPb1coxil,
  tracking_liTG5GM0Yx,
  handlers_oZWLY9pUCB,
  store_9xNuDHGAVU,
  vee_validate_KcKlKmvCrJ
]