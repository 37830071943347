import { default as _404VP3H4oMKLaMeta } from "/root/injective/injective-explorer/pages/404.vue?macro=true";
import { default as cw20balances3scklCrppyMeta } from "/root/injective/injective-explorer/pages/account/[account]/cw20balances.vue?macro=true";
import { default as indexEtKApAjMUFMeta } from "/root/injective/injective-explorer/pages/account/[account]/index.vue?macro=true";
import { default as indexASn0H6GWapMeta } from "/root/injective/injective-explorer/pages/account/[account]/staking/index.vue?macro=true";
import { default as redelegationsi4Y8e8HJ24Meta } from "/root/injective/injective-explorer/pages/account/[account]/staking/redelegations.vue?macro=true";
import { default as unbondingsLra5BidAnaMeta } from "/root/injective/injective-explorer/pages/account/[account]/staking/unbondings.vue?macro=true";
import { default as stakingM7WPntzGg2Meta } from "/root/injective/injective-explorer/pages/account/[account]/staking.vue?macro=true";
import { default as transactionsC1GKJEVodgMeta } from "/root/injective/injective-explorer/pages/account/[account]/transactions.vue?macro=true";
import { default as _91account_93DGLYNxvW9qMeta } from "/root/injective/injective-explorer/pages/account/[account].vue?macro=true";
import { default as asset5fnK4ofv1ZMeta } from "/root/injective/injective-explorer/pages/asset.vue?macro=true";
import { default as indexTmsuElRnfNMeta } from "/root/injective/injective-explorer/pages/assets/index.vue?macro=true";
import { default as smart_45contracts4VeFRZarQmMeta } from "/root/injective/injective-explorer/pages/assets/smart-contracts.vue?macro=true";
import { default as assetsHcZHB6i7BVMeta } from "/root/injective/injective-explorer/pages/assets.vue?macro=true";
import { default as _91block_93xUC4khrIXxMeta } from "/root/injective/injective-explorer/pages/block/[block].vue?macro=true";
import { default as blocksegUtDtgV3CMeta } from "/root/injective/injective-explorer/pages/blocks.vue?macro=true";
import { default as _91code_93Q2BY7vEWapMeta } from "/root/injective/injective-explorer/pages/code/[code].vue?macro=true";
import { default as codesGfAJNlJycQMeta } from "/root/injective/injective-explorer/pages/codes.vue?macro=true";
import { default as community_45spend_45poolWGL8qqFK5xMeta } from "/root/injective/injective-explorer/pages/community-spend-pool.vue?macro=true";
import { default as balances7a9hXHtGjOMeta } from "/root/injective/injective-explorer/pages/contract/[contract]/balances.vue?macro=true";
import { default as cw20balancesi6ePunGBeYMeta } from "/root/injective/injective-explorer/pages/contract/[contract]/cw20balances.vue?macro=true";
import { default as indexZGpec3rzKQMeta } from "/root/injective/injective-explorer/pages/contract/[contract]/index.vue?macro=true";
import { default as init8Xmve2jK5bMeta } from "/root/injective/injective-explorer/pages/contract/[contract]/init.vue?macro=true";
import { default as token_45holdersFIsWfKzYFmMeta } from "/root/injective/injective-explorer/pages/contract/[contract]/token-holders.vue?macro=true";
import { default as _91contract_93GlV318eHqYMeta } from "/root/injective/injective-explorer/pages/contract/[contract].vue?macro=true";
import { default as contracts1RUTeIvKxlMeta } from "/root/injective/injective-explorer/pages/contracts.vue?macro=true";
import { default as featured_45dappsWBPMTNaNCVMeta } from "/root/injective/injective-explorer/pages/featured-dapps.vue?macro=true";
import { default as indexWZOyDGGrETMeta } from "/root/injective/injective-explorer/pages/index.vue?macro=true";
import { default as maintenancepyNbpbFYruMeta } from "/root/injective/injective-explorer/pages/maintenance.vue?macro=true";
import { default as funding_45rate_45historyTGshQH2inOMeta } from "/root/injective/injective-explorer/pages/markets/funding-rate-history.vue?macro=true";
import { default as indexmMfb5AoAc5Meta } from "/root/injective/injective-explorer/pages/markets/index.vue?macro=true";
import { default as realtime_45funding_45rateumdgJ3vpsXMeta } from "/root/injective/injective-explorer/pages/markets/realtime-funding-rate.vue?macro=true";
import { default as _91marketId_93rn5xB5gsIkMeta } from "/root/injective/injective-explorer/pages/markets/spot/[marketId].vue?macro=true";
import { default as trading_45rulesX6fKsWlUhiMeta } from "/root/injective/injective-explorer/pages/markets/trading-rules.vue?macro=true";
import { default as marketsVfkNwOYM9KMeta } from "/root/injective/injective-explorer/pages/markets.vue?macro=true";
import { default as raw_45txYYZOTj6lA5Meta } from "/root/injective/injective-explorer/pages/raw-tx.vue?macro=true";
import { default as _91tradeId_93PPpdtTkqjjMeta } from "/root/injective/injective-explorer/pages/trade-history/derivatives/[tradeId].vue?macro=true";
import { default as indexYJ8GcAEGLGMeta } from "/root/injective/injective-explorer/pages/trade-history/derivatives/index.vue?macro=true";
import { default as indexoVQXdDDGgUMeta } from "/root/injective/injective-explorer/pages/trade-history/index.vue?macro=true";
import { default as _91tradeId_93ovBqdHLNdVMeta } from "/root/injective/injective-explorer/pages/trade-history/spot/[tradeId].vue?macro=true";
import { default as indexPrDLL3R2lqMeta } from "/root/injective/injective-explorer/pages/trade-history/spot/index.vue?macro=true";
import { default as trade_45history4wdxWrri2GMeta } from "/root/injective/injective-explorer/pages/trade-history.vue?macro=true";
import { default as event_45logsDp3qPiJPVyMeta } from "/root/injective/injective-explorer/pages/transaction/[transaction]/event-logs.vue?macro=true";
import { default as indexWJ8edPC8EWMeta } from "/root/injective/injective-explorer/pages/transaction/[transaction]/index.vue?macro=true";
import { default as _91transaction_93O7jI16oisQMeta } from "/root/injective/injective-explorer/pages/transaction/[transaction].vue?macro=true";
import { default as transactionsc0W8ckAwY1Meta } from "/root/injective/injective-explorer/pages/transactions.vue?macro=true";
export default [
  {
    name: _404VP3H4oMKLaMeta?.name ?? "404",
    path: _404VP3H4oMKLaMeta?.path ?? "/404",
    meta: _404VP3H4oMKLaMeta || {},
    alias: _404VP3H4oMKLaMeta?.alias || [],
    redirect: _404VP3H4oMKLaMeta?.redirect,
    component: () => import("/root/injective/injective-explorer/pages/404.vue").then(m => m.default || m)
  },
  {
    name: _91account_93DGLYNxvW9qMeta?.name ?? undefined,
    path: _91account_93DGLYNxvW9qMeta?.path ?? "/account/:account()",
    meta: _91account_93DGLYNxvW9qMeta || {},
    alias: _91account_93DGLYNxvW9qMeta?.alias || [],
    redirect: _91account_93DGLYNxvW9qMeta?.redirect,
    component: () => import("/root/injective/injective-explorer/pages/account/[account].vue").then(m => m.default || m),
    children: [
  {
    name: cw20balances3scklCrppyMeta?.name ?? "account-account-cw20balances",
    path: cw20balances3scklCrppyMeta?.path ?? "cw20balances",
    meta: cw20balances3scklCrppyMeta || {},
    alias: cw20balances3scklCrppyMeta?.alias || [],
    redirect: cw20balances3scklCrppyMeta?.redirect,
    component: () => import("/root/injective/injective-explorer/pages/account/[account]/cw20balances.vue").then(m => m.default || m)
  },
  {
    name: indexEtKApAjMUFMeta?.name ?? "account-account",
    path: indexEtKApAjMUFMeta?.path ?? "",
    meta: indexEtKApAjMUFMeta || {},
    alias: indexEtKApAjMUFMeta?.alias || [],
    redirect: indexEtKApAjMUFMeta?.redirect,
    component: () => import("/root/injective/injective-explorer/pages/account/[account]/index.vue").then(m => m.default || m)
  },
  {
    name: stakingM7WPntzGg2Meta?.name ?? undefined,
    path: stakingM7WPntzGg2Meta?.path ?? "staking",
    meta: stakingM7WPntzGg2Meta || {},
    alias: stakingM7WPntzGg2Meta?.alias || [],
    redirect: stakingM7WPntzGg2Meta?.redirect,
    component: () => import("/root/injective/injective-explorer/pages/account/[account]/staking.vue").then(m => m.default || m),
    children: [
  {
    name: indexASn0H6GWapMeta?.name ?? "account-account-staking",
    path: indexASn0H6GWapMeta?.path ?? "",
    meta: indexASn0H6GWapMeta || {},
    alias: indexASn0H6GWapMeta?.alias || [],
    redirect: indexASn0H6GWapMeta?.redirect,
    component: () => import("/root/injective/injective-explorer/pages/account/[account]/staking/index.vue").then(m => m.default || m)
  },
  {
    name: redelegationsi4Y8e8HJ24Meta?.name ?? "account-account-staking-redelegations",
    path: redelegationsi4Y8e8HJ24Meta?.path ?? "redelegations",
    meta: redelegationsi4Y8e8HJ24Meta || {},
    alias: redelegationsi4Y8e8HJ24Meta?.alias || [],
    redirect: redelegationsi4Y8e8HJ24Meta?.redirect,
    component: () => import("/root/injective/injective-explorer/pages/account/[account]/staking/redelegations.vue").then(m => m.default || m)
  },
  {
    name: unbondingsLra5BidAnaMeta?.name ?? "account-account-staking-unbondings",
    path: unbondingsLra5BidAnaMeta?.path ?? "unbondings",
    meta: unbondingsLra5BidAnaMeta || {},
    alias: unbondingsLra5BidAnaMeta?.alias || [],
    redirect: unbondingsLra5BidAnaMeta?.redirect,
    component: () => import("/root/injective/injective-explorer/pages/account/[account]/staking/unbondings.vue").then(m => m.default || m)
  }
]
  },
  {
    name: transactionsC1GKJEVodgMeta?.name ?? "account-account-transactions",
    path: transactionsC1GKJEVodgMeta?.path ?? "transactions",
    meta: transactionsC1GKJEVodgMeta || {},
    alias: transactionsC1GKJEVodgMeta?.alias || [],
    redirect: transactionsC1GKJEVodgMeta?.redirect,
    component: () => import("/root/injective/injective-explorer/pages/account/[account]/transactions.vue").then(m => m.default || m)
  }
]
  },
  {
    name: asset5fnK4ofv1ZMeta?.name ?? "asset",
    path: asset5fnK4ofv1ZMeta?.path ?? "/asset",
    meta: asset5fnK4ofv1ZMeta || {},
    alias: asset5fnK4ofv1ZMeta?.alias || [],
    redirect: asset5fnK4ofv1ZMeta?.redirect,
    component: () => import("/root/injective/injective-explorer/pages/asset.vue").then(m => m.default || m)
  },
  {
    name: assetsHcZHB6i7BVMeta?.name ?? undefined,
    path: assetsHcZHB6i7BVMeta?.path ?? "/assets",
    meta: assetsHcZHB6i7BVMeta || {},
    alias: assetsHcZHB6i7BVMeta?.alias || [],
    redirect: assetsHcZHB6i7BVMeta?.redirect,
    component: () => import("/root/injective/injective-explorer/pages/assets.vue").then(m => m.default || m),
    children: [
  {
    name: indexTmsuElRnfNMeta?.name ?? "assets",
    path: indexTmsuElRnfNMeta?.path ?? "",
    meta: indexTmsuElRnfNMeta || {},
    alias: indexTmsuElRnfNMeta?.alias || [],
    redirect: indexTmsuElRnfNMeta?.redirect,
    component: () => import("/root/injective/injective-explorer/pages/assets/index.vue").then(m => m.default || m)
  },
  {
    name: smart_45contracts4VeFRZarQmMeta?.name ?? "assets-smart-contracts",
    path: smart_45contracts4VeFRZarQmMeta?.path ?? "smart-contracts",
    meta: smart_45contracts4VeFRZarQmMeta || {},
    alias: smart_45contracts4VeFRZarQmMeta?.alias || [],
    redirect: smart_45contracts4VeFRZarQmMeta?.redirect,
    component: () => import("/root/injective/injective-explorer/pages/assets/smart-contracts.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91block_93xUC4khrIXxMeta?.name ?? "block-block",
    path: _91block_93xUC4khrIXxMeta?.path ?? "/block/:block()",
    meta: _91block_93xUC4khrIXxMeta || {},
    alias: _91block_93xUC4khrIXxMeta?.alias || [],
    redirect: _91block_93xUC4khrIXxMeta?.redirect,
    component: () => import("/root/injective/injective-explorer/pages/block/[block].vue").then(m => m.default || m)
  },
  {
    name: blocksegUtDtgV3CMeta?.name ?? "blocks",
    path: blocksegUtDtgV3CMeta?.path ?? "/blocks",
    meta: blocksegUtDtgV3CMeta || {},
    alias: blocksegUtDtgV3CMeta?.alias || [],
    redirect: blocksegUtDtgV3CMeta?.redirect,
    component: () => import("/root/injective/injective-explorer/pages/blocks.vue").then(m => m.default || m)
  },
  {
    name: _91code_93Q2BY7vEWapMeta?.name ?? "code-code",
    path: _91code_93Q2BY7vEWapMeta?.path ?? "/code/:code()",
    meta: _91code_93Q2BY7vEWapMeta || {},
    alias: _91code_93Q2BY7vEWapMeta?.alias || [],
    redirect: _91code_93Q2BY7vEWapMeta?.redirect,
    component: () => import("/root/injective/injective-explorer/pages/code/[code].vue").then(m => m.default || m)
  },
  {
    name: codesGfAJNlJycQMeta?.name ?? "codes",
    path: codesGfAJNlJycQMeta?.path ?? "/codes",
    meta: codesGfAJNlJycQMeta || {},
    alias: codesGfAJNlJycQMeta?.alias || [],
    redirect: codesGfAJNlJycQMeta?.redirect,
    component: () => import("/root/injective/injective-explorer/pages/codes.vue").then(m => m.default || m)
  },
  {
    name: community_45spend_45poolWGL8qqFK5xMeta?.name ?? "community-spend-pool",
    path: community_45spend_45poolWGL8qqFK5xMeta?.path ?? "/community-spend-pool",
    meta: community_45spend_45poolWGL8qqFK5xMeta || {},
    alias: community_45spend_45poolWGL8qqFK5xMeta?.alias || [],
    redirect: community_45spend_45poolWGL8qqFK5xMeta?.redirect,
    component: () => import("/root/injective/injective-explorer/pages/community-spend-pool.vue").then(m => m.default || m)
  },
  {
    name: _91contract_93GlV318eHqYMeta?.name ?? undefined,
    path: _91contract_93GlV318eHqYMeta?.path ?? "/contract/:contract()",
    meta: _91contract_93GlV318eHqYMeta || {},
    alias: _91contract_93GlV318eHqYMeta?.alias || [],
    redirect: _91contract_93GlV318eHqYMeta?.redirect,
    component: () => import("/root/injective/injective-explorer/pages/contract/[contract].vue").then(m => m.default || m),
    children: [
  {
    name: balances7a9hXHtGjOMeta?.name ?? "contract-contract-balances",
    path: balances7a9hXHtGjOMeta?.path ?? "balances",
    meta: balances7a9hXHtGjOMeta || {},
    alias: balances7a9hXHtGjOMeta?.alias || [],
    redirect: balances7a9hXHtGjOMeta?.redirect,
    component: () => import("/root/injective/injective-explorer/pages/contract/[contract]/balances.vue").then(m => m.default || m)
  },
  {
    name: cw20balancesi6ePunGBeYMeta?.name ?? "contract-contract-cw20balances",
    path: cw20balancesi6ePunGBeYMeta?.path ?? "cw20balances",
    meta: cw20balancesi6ePunGBeYMeta || {},
    alias: cw20balancesi6ePunGBeYMeta?.alias || [],
    redirect: cw20balancesi6ePunGBeYMeta?.redirect,
    component: () => import("/root/injective/injective-explorer/pages/contract/[contract]/cw20balances.vue").then(m => m.default || m)
  },
  {
    name: indexZGpec3rzKQMeta?.name ?? "contract-contract",
    path: indexZGpec3rzKQMeta?.path ?? "",
    meta: indexZGpec3rzKQMeta || {},
    alias: indexZGpec3rzKQMeta?.alias || [],
    redirect: indexZGpec3rzKQMeta?.redirect,
    component: () => import("/root/injective/injective-explorer/pages/contract/[contract]/index.vue").then(m => m.default || m)
  },
  {
    name: init8Xmve2jK5bMeta?.name ?? "contract-contract-init",
    path: init8Xmve2jK5bMeta?.path ?? "init",
    meta: init8Xmve2jK5bMeta || {},
    alias: init8Xmve2jK5bMeta?.alias || [],
    redirect: init8Xmve2jK5bMeta?.redirect,
    component: () => import("/root/injective/injective-explorer/pages/contract/[contract]/init.vue").then(m => m.default || m)
  },
  {
    name: token_45holdersFIsWfKzYFmMeta?.name ?? "contract-contract-token-holders",
    path: token_45holdersFIsWfKzYFmMeta?.path ?? "token-holders",
    meta: token_45holdersFIsWfKzYFmMeta || {},
    alias: token_45holdersFIsWfKzYFmMeta?.alias || [],
    redirect: token_45holdersFIsWfKzYFmMeta?.redirect,
    component: () => import("/root/injective/injective-explorer/pages/contract/[contract]/token-holders.vue").then(m => m.default || m)
  }
]
  },
  {
    name: contracts1RUTeIvKxlMeta?.name ?? "contracts",
    path: contracts1RUTeIvKxlMeta?.path ?? "/contracts",
    meta: contracts1RUTeIvKxlMeta || {},
    alias: contracts1RUTeIvKxlMeta?.alias || [],
    redirect: contracts1RUTeIvKxlMeta?.redirect,
    component: () => import("/root/injective/injective-explorer/pages/contracts.vue").then(m => m.default || m)
  },
  {
    name: featured_45dappsWBPMTNaNCVMeta?.name ?? "featured-dapps",
    path: featured_45dappsWBPMTNaNCVMeta?.path ?? "/featured-dapps",
    meta: featured_45dappsWBPMTNaNCVMeta || {},
    alias: featured_45dappsWBPMTNaNCVMeta?.alias || [],
    redirect: featured_45dappsWBPMTNaNCVMeta?.redirect,
    component: () => import("/root/injective/injective-explorer/pages/featured-dapps.vue").then(m => m.default || m)
  },
  {
    name: indexWZOyDGGrETMeta?.name ?? "index",
    path: indexWZOyDGGrETMeta?.path ?? "/",
    meta: indexWZOyDGGrETMeta || {},
    alias: indexWZOyDGGrETMeta?.alias || [],
    redirect: indexWZOyDGGrETMeta?.redirect,
    component: () => import("/root/injective/injective-explorer/pages/index.vue").then(m => m.default || m)
  },
  {
    name: maintenancepyNbpbFYruMeta?.name ?? "maintenance",
    path: maintenancepyNbpbFYruMeta?.path ?? "/maintenance",
    meta: maintenancepyNbpbFYruMeta || {},
    alias: maintenancepyNbpbFYruMeta?.alias || [],
    redirect: maintenancepyNbpbFYruMeta?.redirect,
    component: () => import("/root/injective/injective-explorer/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: marketsVfkNwOYM9KMeta?.name ?? undefined,
    path: marketsVfkNwOYM9KMeta?.path ?? "/markets",
    meta: marketsVfkNwOYM9KMeta || {},
    alias: marketsVfkNwOYM9KMeta?.alias || [],
    redirect: marketsVfkNwOYM9KMeta?.redirect,
    component: () => import("/root/injective/injective-explorer/pages/markets.vue").then(m => m.default || m),
    children: [
  {
    name: funding_45rate_45historyTGshQH2inOMeta?.name ?? "markets-funding-rate-history",
    path: funding_45rate_45historyTGshQH2inOMeta?.path ?? "funding-rate-history",
    meta: funding_45rate_45historyTGshQH2inOMeta || {},
    alias: funding_45rate_45historyTGshQH2inOMeta?.alias || [],
    redirect: funding_45rate_45historyTGshQH2inOMeta?.redirect,
    component: () => import("/root/injective/injective-explorer/pages/markets/funding-rate-history.vue").then(m => m.default || m)
  },
  {
    name: indexmMfb5AoAc5Meta?.name ?? "markets",
    path: indexmMfb5AoAc5Meta?.path ?? "",
    meta: indexmMfb5AoAc5Meta || {},
    alias: indexmMfb5AoAc5Meta?.alias || [],
    redirect: indexmMfb5AoAc5Meta?.redirect,
    component: () => import("/root/injective/injective-explorer/pages/markets/index.vue").then(m => m.default || m)
  },
  {
    name: realtime_45funding_45rateumdgJ3vpsXMeta?.name ?? "markets-realtime-funding-rate",
    path: realtime_45funding_45rateumdgJ3vpsXMeta?.path ?? "realtime-funding-rate",
    meta: realtime_45funding_45rateumdgJ3vpsXMeta || {},
    alias: realtime_45funding_45rateumdgJ3vpsXMeta?.alias || [],
    redirect: realtime_45funding_45rateumdgJ3vpsXMeta?.redirect,
    component: () => import("/root/injective/injective-explorer/pages/markets/realtime-funding-rate.vue").then(m => m.default || m)
  },
  {
    name: _91marketId_93rn5xB5gsIkMeta?.name ?? "markets-spot-marketId",
    path: _91marketId_93rn5xB5gsIkMeta?.path ?? "spot/:marketId()",
    meta: _91marketId_93rn5xB5gsIkMeta || {},
    alias: _91marketId_93rn5xB5gsIkMeta?.alias || [],
    redirect: _91marketId_93rn5xB5gsIkMeta?.redirect,
    component: () => import("/root/injective/injective-explorer/pages/markets/spot/[marketId].vue").then(m => m.default || m)
  },
  {
    name: trading_45rulesX6fKsWlUhiMeta?.name ?? "markets-trading-rules",
    path: trading_45rulesX6fKsWlUhiMeta?.path ?? "trading-rules",
    meta: trading_45rulesX6fKsWlUhiMeta || {},
    alias: trading_45rulesX6fKsWlUhiMeta?.alias || [],
    redirect: trading_45rulesX6fKsWlUhiMeta?.redirect,
    component: () => import("/root/injective/injective-explorer/pages/markets/trading-rules.vue").then(m => m.default || m)
  },
  {
    name: _91marketId_93rn5xB5gsIkMeta?.name ?? "markets-futures-marketId",
    path: _91marketId_93rn5xB5gsIkMeta?.path ?? "/futures/:marketId()",
    meta: _91marketId_93rn5xB5gsIkMeta || {},
    alias: _91marketId_93rn5xB5gsIkMeta?.alias || [],
    redirect: _91marketId_93rn5xB5gsIkMeta?.redirect,
    component: () => import("/root/injective/injective-explorer/pages/markets/spot/[marketId].vue").then(m => m.default || m)
  },
  {
    name: _91marketId_93rn5xB5gsIkMeta?.name ?? "markets-perpetual-marketId",
    path: _91marketId_93rn5xB5gsIkMeta?.path ?? "/perpetual/:marketId()",
    meta: _91marketId_93rn5xB5gsIkMeta || {},
    alias: _91marketId_93rn5xB5gsIkMeta?.alias || [],
    redirect: _91marketId_93rn5xB5gsIkMeta?.redirect,
    component: () => import("/root/injective/injective-explorer/pages/markets/spot/[marketId].vue").then(m => m.default || m)
  },
  {
    name: _91marketId_93rn5xB5gsIkMeta?.name ?? "markets-binary-options-marketId",
    path: _91marketId_93rn5xB5gsIkMeta?.path ?? "/binary-options/:marketId()",
    meta: _91marketId_93rn5xB5gsIkMeta || {},
    alias: _91marketId_93rn5xB5gsIkMeta?.alias || [],
    redirect: _91marketId_93rn5xB5gsIkMeta?.redirect,
    component: () => import("/root/injective/injective-explorer/pages/markets/spot/[marketId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: raw_45txYYZOTj6lA5Meta?.name ?? "raw-tx",
    path: raw_45txYYZOTj6lA5Meta?.path ?? "/raw-tx",
    meta: raw_45txYYZOTj6lA5Meta || {},
    alias: raw_45txYYZOTj6lA5Meta?.alias || [],
    redirect: raw_45txYYZOTj6lA5Meta?.redirect,
    component: () => import("/root/injective/injective-explorer/pages/raw-tx.vue").then(m => m.default || m)
  },
  {
    name: trade_45history4wdxWrri2GMeta?.name ?? undefined,
    path: trade_45history4wdxWrri2GMeta?.path ?? "/trade-history",
    meta: trade_45history4wdxWrri2GMeta || {},
    alias: trade_45history4wdxWrri2GMeta?.alias || [],
    redirect: trade_45history4wdxWrri2GMeta?.redirect,
    component: () => import("/root/injective/injective-explorer/pages/trade-history.vue").then(m => m.default || m),
    children: [
  {
    name: _91tradeId_93PPpdtTkqjjMeta?.name ?? "trade-history-derivatives-tradeId",
    path: _91tradeId_93PPpdtTkqjjMeta?.path ?? "derivatives/:tradeId()",
    meta: _91tradeId_93PPpdtTkqjjMeta || {},
    alias: _91tradeId_93PPpdtTkqjjMeta?.alias || [],
    redirect: _91tradeId_93PPpdtTkqjjMeta?.redirect,
    component: () => import("/root/injective/injective-explorer/pages/trade-history/derivatives/[tradeId].vue").then(m => m.default || m)
  },
  {
    name: indexYJ8GcAEGLGMeta?.name ?? "trade-history-derivatives",
    path: indexYJ8GcAEGLGMeta?.path ?? "derivatives",
    meta: indexYJ8GcAEGLGMeta || {},
    alias: indexYJ8GcAEGLGMeta?.alias || [],
    redirect: indexYJ8GcAEGLGMeta?.redirect,
    component: () => import("/root/injective/injective-explorer/pages/trade-history/derivatives/index.vue").then(m => m.default || m)
  },
  {
    name: indexoVQXdDDGgUMeta?.name ?? "trade-history",
    path: indexoVQXdDDGgUMeta?.path ?? "",
    meta: indexoVQXdDDGgUMeta || {},
    alias: indexoVQXdDDGgUMeta?.alias || [],
    redirect: indexoVQXdDDGgUMeta?.redirect,
    component: () => import("/root/injective/injective-explorer/pages/trade-history/index.vue").then(m => m.default || m)
  },
  {
    name: _91tradeId_93ovBqdHLNdVMeta?.name ?? "trade-history-spot-tradeId",
    path: _91tradeId_93ovBqdHLNdVMeta?.path ?? "spot/:tradeId()",
    meta: _91tradeId_93ovBqdHLNdVMeta || {},
    alias: _91tradeId_93ovBqdHLNdVMeta?.alias || [],
    redirect: _91tradeId_93ovBqdHLNdVMeta?.redirect,
    component: () => import("/root/injective/injective-explorer/pages/trade-history/spot/[tradeId].vue").then(m => m.default || m)
  },
  {
    name: indexPrDLL3R2lqMeta?.name ?? "trade-history-spot",
    path: indexPrDLL3R2lqMeta?.path ?? "spot",
    meta: indexPrDLL3R2lqMeta || {},
    alias: indexPrDLL3R2lqMeta?.alias || [],
    redirect: indexPrDLL3R2lqMeta?.redirect,
    component: () => import("/root/injective/injective-explorer/pages/trade-history/spot/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91transaction_93O7jI16oisQMeta?.name ?? undefined,
    path: _91transaction_93O7jI16oisQMeta?.path ?? "/transaction/:transaction()",
    meta: _91transaction_93O7jI16oisQMeta || {},
    alias: _91transaction_93O7jI16oisQMeta?.alias || [],
    redirect: _91transaction_93O7jI16oisQMeta?.redirect,
    component: () => import("/root/injective/injective-explorer/pages/transaction/[transaction].vue").then(m => m.default || m),
    children: [
  {
    name: event_45logsDp3qPiJPVyMeta?.name ?? "transaction-transaction-event-logs",
    path: event_45logsDp3qPiJPVyMeta?.path ?? "event-logs",
    meta: event_45logsDp3qPiJPVyMeta || {},
    alias: event_45logsDp3qPiJPVyMeta?.alias || [],
    redirect: event_45logsDp3qPiJPVyMeta?.redirect,
    component: () => import("/root/injective/injective-explorer/pages/transaction/[transaction]/event-logs.vue").then(m => m.default || m)
  },
  {
    name: indexWJ8edPC8EWMeta?.name ?? "transaction-transaction",
    path: indexWJ8edPC8EWMeta?.path ?? "",
    meta: indexWJ8edPC8EWMeta || {},
    alias: indexWJ8edPC8EWMeta?.alias || [],
    redirect: indexWJ8edPC8EWMeta?.redirect,
    component: () => import("/root/injective/injective-explorer/pages/transaction/[transaction]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: transactionsc0W8ckAwY1Meta?.name ?? "transactions",
    path: transactionsc0W8ckAwY1Meta?.path ?? "/transactions",
    meta: transactionsc0W8ckAwY1Meta || {},
    alias: transactionsc0W8ckAwY1Meta?.alias || [],
    redirect: transactionsc0W8ckAwY1Meta?.redirect,
    component: () => import("/root/injective/injective-explorer/pages/transactions.vue").then(m => m.default || m)
  }
]